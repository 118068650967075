<template>
  <div class="profile">
    <h3>Profile for {{formData.firstName}} {{formData.lastName}}</h3>

    <p>
      [ <router-link :to="{name: 'Applications'}" class="custom-fg-dark">Return to Application Listing</router-link> ]
    </p>

    <form class="ml-2 mr-2">

      <div class="form-row ml-2 mr-2">
        <div class="form-group col-md-12">
          <label for="username" class="form-control-lg font-weight-bold">User Name: {{formData.username}}</label>
        </div>
      </div>

      <div class="form-row ml-2 mr-2">
        <div class="form-group col-md-6">
          <label for="firstName" class="form-control-lg">First Name</label>
          <input
            type="text"
            class="form-control form-control-lg"
            id="firstName"
            placeholder="First Name"
            v-model="formData.firstName"
            @change="changeValue('firstName')"/>
        </div>
        <div class="form-group col-md-6">
          <label for="firstName" class="form-control-lg">Last Name</label>
          <input
            type="text"
            class="form-control form-control-lg"
            id="lastName"
            placeholder="Last Name"
            v-model="formData.lastName"
            @change="changeValue('lastName')"/>
        </div>
      </div>

      <div class="form-row ml-2 mr-2">
        <div class="form-group col-md-12">
          <label for="email" class="form-control-lg">Email Address</label>
          <input
            type="text"
            class="form-control form-control-lg"
            id="email"
            placeholder="Email Address"
            v-model="formData.email"
            @change="changeValue('email')"/>
        </div>
      </div>

    </form>

    <h3 class="mt-4">Change Password</h3>

    <div v-if="showPasswordChangeError" class="alert alert-danger alert-dismissible fade show" role="alert">
      <strong>Password Change Failed!</strong> Message from server: {{passwordChangeError}}
      <button type="button" class="close" aria-label="Close" @click="showPasswordChangeError=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div v-if="showPasswordChangeSuccess" class="alert alert-success alert-dismissible fade show" role="alert">
      <strong>Password Changed Successfully</strong>
      <button type="button" class="close" aria-label="Close" @click="showPasswordChangeSuccess=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <form class="ml-2 mr-2">

      <div class="form-row ml-2 mr-2">
        <div class="form-group col-md-5">
          <label for="newPassword" class="form-control-lg">New Password</label>
          <span class="input-help">
            <input
              type="password"
              class="form-control form-control-lg"
              id="newPassword"
              placeholder="Enter New Password"
              v-model="formData.newPassword"
              @change="checkPasswordsMatch()"/>
            <small id="passwordHelpBlock" class="ml-2 form-text text-danger" v-if="!passwordsMatch">Passwords do not match</small>
          </span>
        </div>
        <div class="form-group col-md-5">
          <label for="confirmNewPassword" class="form-control-lg">Confirm New Password</label>
          <span class="input-help">
            <input
              type="password"
              class="form-control form-control-lg"
              id="confirmNewPassword"
              placeholder="Confirm New Password"
              v-model="formData.confirmNewPassword"
              @change="checkPasswordsMatch()"/>
            <small id="passwordHelpBlock" class="ml-2 form-text text-danger" v-if="!passwordsMatch">Passwords do not match</small>
          </span>
        </div>
        <div class="form-group col-md-2" v-if="showChangePasswordButton">
          <button id="changePasswordButton" @click.prevent="changePassword" class="btn btn-secondary" style="margin-top: 3.75rem;">Go</button>
        </div>
      </div>

    </form>

  </div>
</template>

<script>
import Store from '@/store'
import User from '@/services/UserService'

export default {
  name: 'profile',
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  },
  computed: {
    showChangePasswordButton: function() {
      var showButton = false;

      if (this.formData.newPassword != null
        && this.formData.newPassword.length > 0
        && this.formData.confirmNewPassword != null
        && this.formData.confirmNewPassword.length > 0
        && this.formData.newPassword === this.formData.confirmNewPassword) {
          showButton = true;
      }

      return showButton
    },
  },
  created: async function () {
    this.formData.username = this.userInfo.username;
    this.formData.firstName = this.userInfo.firstName;
    this.formData.lastName = this.userInfo.lastName;
    this.formData.email = this.userInfo.email;
  },
  data: function () {
    return {
      userInfo: Store.state.userInfo,
      formData: {},
      passwordChangeError: null,
      passwordsMatch: true,
      showPasswordChangeError: false,
      showPasswordChangeSuccess: false,
    };
  },
  methods: {
    async changePassword() {
      var passwordInfo = { password : this.formData.newPassword };
      var result = await User.changeMyPassword(passwordInfo);

      if (result.status === 500) {
        this.passwordChangeError = result.description;
        this.showPasswordChangeError = true;

      } else {
        this.showPasswordChangeSuccess = true;
        this.formData.newPassword = null;
        this.formData.confirmNewPassword = null;
      }
    },
    changeValue(fieldName) {
      if (this.formData[fieldName] != this.userInfo[fieldName]) {
        this.userInfo[fieldName] = this.formData[fieldName];
        this.saveMyInfo();
      }
    },
    checkPasswordsMatch() {
      if (this.formData.newPassword != this.formData.confirmNewPassword) {
        this.passwordsMatch = false;
        this.showPasswordChangeSuccess = false;
        this.showPasswordChangeError = false;
      } else {
        this.passwordsMatch = true;
      }
    },
    saveMyInfo() {
      let updatedInfo = {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        email: this.formData.email,
      };

      User.updateMyInfo(updatedInfo);
    }
  }
}
</script>
